import {FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup} from "@material-ui/core";
import {useController} from "react-hook-form";
import {Info} from "./Info";
import {useRef} from "react";


export function Weight({section, control}) {

    const {
        field: { value, onChange },
        fieldState: { error }
    } = useController({
        name: section.name,
        control,
        rules: {
            required: {
                value: true,
                message: 'Dit is een verplicht veld'
            }
        },
        defaultValue: "",
    });

    const ref = useRef();

    return (
        <FormControl component="fieldset" error={!!error} fullWidth>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <FormLabel style={{float: 'left'}} ref={ref} component="legend">{section.label}</FormLabel>
                {section.info && <div style={{marginLeft: '10px'}}><Info info={section.info}/></div>}
            </div>
            <FormHelperText>{error ? error.message : null}</FormHelperText>
            <RadioGroup value={value} style={{justifyContent: 'space-between', alignContent: 'center'}} onChange={(e) => {
                onChange(e);
                ref.current.scrollIntoView({behavior: 'smooth', block: 'start'})
            }} row>
                <FormControlLabel style={{margin: 0}} value="1" control={<Radio/>} label="1" labelPlacement={"top"}/>
                <FormControlLabel style={{margin: 0}} value="2" control={<Radio/>} label="2" labelPlacement={"top"}/>
                <FormControlLabel style={{margin: 0}} value="3" control={<Radio/>} label="3" labelPlacement={"top"}/>
                <FormControlLabel style={{margin: 0}} value="4" control={<Radio/>} label="4" labelPlacement={"top"}/>
                <FormControlLabel style={{margin: 0}} value="5" control={<Radio/>} label="5" labelPlacement={"top"}/>
            </RadioGroup>
        </FormControl>

    )
}