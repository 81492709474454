import {Snackbar, Typography} from "@material-ui/core";
import {InfoOutlined} from "@material-ui/icons";
import {Fragment, useState} from 'react';
import {blue} from "@material-ui/core/colors";


export function Info({info}) {

    // return (
    //     <Typography color={'textSecondary'} variant={'caption'}>- {info}</Typography>
    // );

    const [open, setOpen] = useState(false);

    return (
        <Fragment>
                <InfoOutlined fontSize={'small'} style={{color: blue[200]}} onClick={() => setOpen(true)} />
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={open}
                    onClose={() => setOpen(false)}
                    message={info}
                    autoHideDuration={5000}
                />
        </Fragment>
    );
}