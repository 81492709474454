import './App.css';
import {
    Card,
    Typography,
    CardContent,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Container,
    Box,
    createTheme,
    MuiThemeProvider
} from "@material-ui/core";
import {createRef, forwardRef, useCallback, useRef, useState} from "react";
import {Survey} from "./Survey";

const theme = createTheme({
    "palette": {
        "common": {"black": "#000", "white": "#fff"},
        "background": {"paper": "#fff", "default": "#fafafa"},
        "primary": {"light": "#7986cb", "main": "rgba(10, 10, 31, 1)", "dark": "#303f9f", "contrastText": "#fff"},
        "secondary": {"light": "#ff4081", "main": "rgba(198, 235, 76, 1)", "dark": "#c51162", "contrastText": "#fff"},
        "error": {"light": "#e57373", "main": "rgba(216, 7, 7, 1)", "dark": "#d32f2f", "contrastText": "#fff"},
        "text": {
            "primary": "rgba(0, 0, 0, 0.87)",
            "secondary": "rgba(0, 0, 0, 0.54)",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "rgba(0, 0, 0, 0.38)"
        }
    }
})

function App() {

    const [land, setLand] = useState(null);
    const [type, setType] = useState(null);
    const landRef = useRef();
    const typeRef = useRef();
    const surveyRef = useRef();

    // const setTypeWithScroll = forwardRef((val) => {
    //     setType(val);
    //     if(surveyRef.current) {
    //         surveyRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    //     }
    // }, [setType, surveyRef])

    return (
        <MuiThemeProvider theme={theme}>
        <Container maxWidth={'md'}>
            <Box>
                <Typography align={'center'} color={'secondary'} gutterBottom variant="h5" component="h2">Vind je match in 1
                    minuut!</Typography>
            </Box>
            <Box mt={2} mb={2}>
                <Card elevation={3} ref={landRef}>
                    <CardContent>
                        <Typography color={'primary'} gutterBottom variant="h5" component="h1">Waar woon je?</Typography>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="land" name="country" value={land}
                                        onChange={(e) => {
                                            setLand(e.target.value);
                                            landRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
                                        }}>
                                <FormControlLabel value="be" control={<Radio/>} label="België"/>
                                <FormControlLabel value="nl" control={<Radio/>} label="Nederland"/>
                            </RadioGroup>
                        </FormControl>

                    </CardContent>
                </Card>
            </Box>

            <Box mt={2} mb={2}>
                <Card elevation={3} ref={typeRef}>
                    <CardContent>
                        <Typography color={'primary'} gutterBottom variant="h5" component="h1">Wat speel je het
                            liefst?</Typography>

                        <FormControl
                            component="fieldset">
                            <RadioGroup aria-label="type" value={type} onChange={(e) => {
                                setType(e.target.value);
                                setTimeout(() => {
                                    if(surveyRef.current) {
                                        surveyRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
                                    }
                                }, 500);
                                // typeRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
                            }
                            }>
                                <FormControlLabel value="sport" control={<Radio/>} label="Sportweddenschappen"/>
                                <FormControlLabel value="casino" control={<Radio/>} label="Casino"/>
                            </RadioGroup>
                        </FormControl>

                    </CardContent>
                </Card>
            </Box>

            {land && type && <div ref={surveyRef}><Survey land={land} type={type}/></div>}
        </Container>
        </MuiThemeProvider>
    );
}

export default App;
