import {Fragment, useCallback, useMemo, useRef, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText, Grid,
    Radio,
    RadioGroup, Snackbar,
    Typography
} from "@material-ui/core";
import {Info} from "./Info";
import {useForm, useController, useWatch} from "react-hook-form";
import {Weight} from "./Weight";
import ReactMarkdown from 'react-markdown'
import axios from "axios";
import * as PropTypes from "prop-types";

const surveys = {
    nl: {
        casino: [
            {
                header: 'Welke type spel speel je het liefst?',
                type: 'radio',
                name: 'typeCasino',
                key: 'typeCasino',
                options: [
                    {
                        value: '0',
                        label: 'Slots',
                        info: 'Win door te spinnen op een online gokkast'
                    },
                    {
                        value: '1',
                        label: 'Live Casino',
                        info: 'Speel Blackjack of roulette met een dealer die je live kan zien'
                    },
                    // {
                    //     value: '2',
                    //     label: 'Dice games',
                    //     info: 'Win door dobbelstenen strategisch te plaatsen'
                    // }
                ]
            },
            {
                header: 'Wat vind je belangrijk?',
                subheader: '1 - Niet belangrijk ; 5 - Zeer belangrijk',
                type: 'multi',
                key: 'belangerijk',
                questions: [
                    {
                        name: 'gebruiksVriendelijkeWebsite',
                        label: 'Gebruiksvriendelijke website',
                        type: 'weight',
                        info: 'Spellen makkelijk terugvinden, snelheid van de website'
                    },
                    {
                        name: 'gebruiksVriendelijkeApp',
                        label: 'Gebruiksvriendelijk spelen op mobiel',
                        type: 'weight',
                        info: 'Kwaliteit van de website op gsm en ipad'
                    },
                    {
                        name: 'klantenDienst',
                        label: 'Klantenservice',
                        type: 'weight',
                        info: 'Beschikbaarheid live chat, duur om antwoord te krijgen, kwaliteit van antwoord'
                    },
                    {
                        name: 'bonussen',
                        label: 'Bonussen',
                        type: 'weight',
                        info: 'Hoe vaak en vrijgevig loyale klanten worden beloond'
                    },
                ]
            },
            {
                header: 'Welke type bonussen verkies je?',
                type: 'radio',
                name: 'typeBonus',
                key: 'typeBonus',
                condition: {
                    key: 'bonussen',
                    cb: (value) => {
                        return value >= 4;
                    }
                },
                options: [
                    {
                        value: '0',
                        label: 'Stortingsbonussen',
                        info: 'Krijg bonusgeld na een storting'
                    },
                    {
                        value: '1',
                        label: 'Gratis spins (Free rounds)',
                        info: 'Je betaalt geen inzet met je spins, maar krijgt wel de winsten'
                    },
                    {
                        value: '2',
                        label: 'Toernooien',
                        info: 'Win van andere spelers en wordt beloond'
                    },
                    {
                        value: '3',
                        label: 'Cashbacks',
                        info: 'Krijg je geld terug als je pech hebt en verliest'
                    },
                    {
                        value: '4',
                        label: 'Verhoogde bonussen voor VIPs',
                        info: 'Wordt extra beloond als je voor grotere bedragen speelt'
                    }
                ]
            },
            {
                header: 'Op welke website ben je reeds geregistreerd?',
                info: 'Op welke sites je ooit een account hebt aangemaakt',
                type: 'checkbox',
                key: 'alreadyRegistered',
                options: [
                    {
                        key: 'toto',
                        name: 'Toto'
                    },
                    {
                        key: 'hollandCasino',
                        name: 'Holland Casino'
                    },
                    {
                        key: 'jacksCasino',
                        name: 'Jack\'s Casino'
                    },
                    // {
                    //     key: 'napoleonGames',
                    //     name: 'Napoleon Games'
                    // },
                    {
                        key: 'bingoal',
                        name: 'Bingoal'
                    },
                    {
                        key: 'betcity',
                        name: 'Betcity'
                    },
                    {
                        key: 'zeturf',
                        name: 'Zeturf'
                    },
                    {
                        key: 'bet364',
                        name: 'Bet365'
                    },
                    {
                        key: 'betway',
                        name: 'Betway'
                    },
                    // {
                    //     key: 'betfirst',
                    //     name: 'Betfirst'
                    // },
                    {
                        key: 'fairplayonline',
                        name: 'Fairplayonline'
                    }
                ]
            }
        ],
        sport: [
            {
                header: 'Op welke sporten wed je regelmatig?',
                type: 'checkbox',
                key: 'regelmatig',
                options: [
                    {
                        key: 'voetbal',
                        name: 'Voetbal'
                    },
                    {
                        key: 'tennis',
                        name: 'Tennis'
                    },
                    {
                        key: 'basketbal',
                        name: 'Basketbal'
                    },
                    {
                        key: 'volleybal',
                        name: 'Volleybal'
                    },
                    {
                        key: 'handbal',
                        name: 'Handbal'
                    },
                    {
                        key: 'wielrennen',
                        name: 'Wielrennen'
                    },
                    {
                        key: 'darts',
                        name: 'Darts'
                    },
                    {
                        key: 'paardenrennen',
                        name: 'Paardenrennen'
                    },
                    {
                        key: 'ijshockey',
                        name: 'Ijshockey'
                    },
                    {
                        key: 'andere',
                        name: 'Andere'
                    }
                ]
            },
            {
                header: 'Wat vind je belangrijk?',
                subheader: '1 - Niet belangrijk ; 5 - Zeer belangrijk',
                key: 'belangrijk',
                type: 'multi',
                questions: [
                    {
                        label: "Hoge odds", info: "Of de odds (quoteringen) gemiddeld hoger zijn dan andere goksites",
                        name: "hogeOdds", type: 'weight'
                    },
                    {
                        label: "Uitgebreide wedmarkten",
                        info: "Of er veel verschillende type weddenschappen worden aangeboden. (vb: schoten op doel, doelpuntenmakers, Tiebreak,…)",
                        name: "wedMarkten",
                        type: 'weight'
                    },
                    {
                        label: "Cash out mogelijkheden",
                        info: "De mogelijkheid om een weddenschap te laten uitbetalen voordat de wedstrijd beëindigd is.",
                        name: "cashOut",
                        type: 'weight'
                    },
                    {
                        label: "Live betting",
                        info: "De gebruiksvriendelijkheid en aanbod van het wedden op een wedstrijd terwijl deze bezig is",
                        name: "liveBetting",
                        type: 'weight'
                    },
                    {
                        label: "Live Streams", info: "De mogelijkheid om de wedstrijd te bekijken tijdens het wedden",
                        name: "liveStreams", type: 'weight'
                    },
                    {
                        label: "Gebruiksvriendelijke website",
                        info: "Weddenschappen en wedstrijden makkelijk terugvinden, snelheid van de website",
                        name: "gebruiksVriendelijkeWebsite",
                        type: 'weight'
                    },
                    {
                        label: "Gebruiksvriendelijk spelen op mobiel", info: "Kwaliteit van de website op gsm en ipad",
                        name: "gebruiksVriendelijkeApp", type: 'weight'
                    },
                    {
                        label: "Klantenservice",
                        info: "Beschikbaarheid live chat, duur om antwoord te krijgen, kwaliteit van antwoord",
                        name: "klantenDienst",
                        type: 'weight'
                    },
                    {
                        label: "Promoties", info: "Hoe vaak en in welke mate loyale klanten worden beloond",
                        name: "bonussen", type: 'weight'
                    }
                ]
            },
            {
                header: 'Stortingsbonussen, free bets, promoties, VIP-voordelen',
                type: 'radio',
                name: 'typeBonus',
                key: 'typeBonus',
                condition: {
                    key: 'bonussen',
                    cb: (value) => {
                        return value >= 4;
                    }
                },
                options: [
                    {
                        value: '0',
                        label: 'Stortingsbonussen',
                        info: 'Krijg bonusgeld na een storting'
                    },
                    {
                        value: '3',
                        label: 'Cashbacks',
                        info: 'Krijg je geld terug als je pech hebt en verliest'
                    },
                    {
                        value: '4',
                        label: 'Bonussen voor VIP\'s',
                        info: 'Wordt extra beloond als je voor grotere bedragen speelt'
                    }
                ]
            },
            {
                header: 'Op welke website ben je reeds geregistreerd?',
                info: 'Op welke sites je ooit een account hebt aangemaakt',
                type: 'checkbox',
                key: 'alreadyRegistered',
                options: [
                    {
                        key: 'toto',
                        name: 'Toto'
                    },
                    {
                        key: 'hollandCasino',
                        name: 'Holland Casino'
                    },
                    {
                        key: 'jacksCasino',
                        name: 'Jack\'s Casino'
                    },
                    // {
                    //     key: 'napoleonGames',
                    //     name: 'Napoleon Games'
                    // },
                    {
                        key: 'bingoal',
                        name: 'Bingoal'
                    },
                    {
                        key: 'betcity',
                        name: 'Betcity'
                    },
                    {
                        key: 'zeturf',
                        name: 'Zeturf'
                    },
                    {
                        key: 'bet364',
                        name: 'Bet365'
                    },
                    {
                        key: 'betway',
                        name: 'Betway'
                    },
                    // {
                    //     key: 'betfirst',
                    //     name: 'Betfirst'
                    // },
                    {
                        key: 'fairplayonline',
                        name: 'Fairplayonline'
                    }
                ]
            }
        ]
    },
    be: {
        casino: [
            {
                header: 'Welke type spel speel je het liefst?',
                type: 'radio',
                name: 'typeCasino',
                key: 'typeCasino',
                options: [
                    {
                        value: '0',
                        label: 'Slots',
                        info: 'Win door te spinnen op een online gokkast'
                    },
                    {
                        value: '1',
                        label: 'Live Casino',
                        info: 'Speel Blackjack of roulette met een dealer die je live kan zien'
                    },
                    {
                        value: '2',
                        label: 'Dice games',
                        info: 'Win door dobbelstenen strategisch te plaatsen'
                    }
                ]
            },
            {
                header: 'Wat vind je belangrijk?',
                subheader: '1 - Niet belangrijk ; 5 - Zeer belangrijk',
                type: 'multi',
                key: 'belangerijk',
                questions: [
                    {
                        name: 'gebruiksVriendelijkeWebsite',
                        label: 'Gebruiksvriendelijke website',
                        type: 'weight',
                        info: 'Spellen makkelijk terugvinden, snelheid van de website'
                    },
                    {
                        name: 'gebruiksVriendelijkeApp',
                        label: 'Gebruiksvriendelijk spelen op mobiel',
                        type: 'weight',
                        info: 'Kwaliteit van de website op gsm en ipad'
                    },
                    {
                        name: 'klantenDienst',
                        label: 'Klantendienst',
                        type: 'weight',
                        info: 'Beschikbaarheid live chat, duur om antwoord te krijgen, kwaliteit van antwoord'
                    },
                    {
                        name: 'bonussen',
                        label: 'Bonussen',
                        type: 'weight',
                        info: 'Hoe vaak en vrijgevig loyale klanten worden beloond'
                    },
                ]
            },
            {
                header: 'Op welke website ben je reeds geregistreerd?',
                info: 'Op welke sites je ooit een account hebt aangemaakt',
                type: 'checkbox',
                key: 'alreadyRegistered',
                options: [
                    {
                        key: 'casino777',
                        name: 'Casino777'
                    },
                    // {
                    //     key: 'betfirst',
                    //     name: 'Betfirst'
                    // },
                    // {
                    //     key: 'napoleonGames',
                    //     name: 'Napoleon Games'
                    // },
                    {
                        key: 'unibet',
                        name: 'Unibet'
                    },
                    {
                        key: 'circus',
                        name: 'Circus'
                    },
                    {
                        key: 'blitz',
                        name: 'Blitz'
                    },
                    {
                        key: 'supergame',
                        name: 'Supergame'
                    },
                    // {
                    //     key: 'casinoBelgium',
                    //     name: 'Casinobelgium'
                    // },
                    {
                        key: 'goldenVegas',
                        name: 'Goldenvegas'
                    },
                    {
                        key: 'goldenPalace',
                        name: 'Goldenpalace'
                    },
                    {
                        key: 'luckyGames',
                        name: 'Luckygames'
                    },
                    {
                        key: 'bwin',
                        name: 'Bwin'
                    },
                    {
                        key: 'ladBrokes',
                        name: 'Ladbrokes'
                    }
                ]
            }
        ],
        sport: [
            {
                header: 'Op welke sporten wed je regelmatig?',
                type: 'checkbox',
                key: 'regelmatig',
                options: [
                    {
                        key: 'voetbal',
                        name: 'Voetbal'
                    },
                    {
                        key: 'tennis',
                        name: 'Tennis'
                    },
                    {
                        key: 'basketbal',
                        name: 'Basketbal'
                    },
                    {
                        key: 'volleybal',
                        name: 'Volleybal'
                    },
                    {
                        key: 'handbal',
                        name: 'Handbal'
                    },
                    {
                        key: 'wielrennen',
                        name: 'Wielrennen'
                    },
                    {
                        key: 'darts',
                        name: 'Darts'
                    },
                    {
                        key: 'paardenrennen',
                        name: 'Paardenrennen'
                    },
                    {
                        key: 'ijshockey',
                        name: 'Ijshockey'
                    },
                    {
                        key: 'andere',
                        name: 'Andere'
                    }
                ]
            },
            {
                header: 'Wat vind je belangrijk?',
                subheader: '1 - Niet belangrijk ; 5 - Zeer belangrijk',
                key: 'belangrijk',
                type: 'multi',
                questions: [
                    {
                        label: "Hoge odds", info: "Of de odds (quoteringen) gemiddeld hoger zijn dan andere goksites",
                        name: "hogeOdds", type: 'weight'
                    },
                    {
                        label: "Uitgebreide wedmarkten",
                        info: "Of er veel verschillende type weddenschappen worden aangeboden. (vb: wedden op corners, doelpuntenmakers, Tiebreak,…)",
                        name: "wedMarkten",
                        type: 'weight'
                    },
                    {
                        label: "Cash out mogelijkheden",
                        info: "De mogelijkheid om een weddenschap te laten uitbetalen voordat de wedstrijd beëindigd is.",
                        name: "cashOut",
                        type: 'weight'
                    },
                    {
                        label: "Live betting",
                        info: "De gebruiksvriendelijkheid en aanbod van het wedden op een wedstrijd terwijl deze bezig is",
                        name: "liveBetting",
                        type: 'weight'
                    },
                    {
                        label: "Live Streams", info: "De mogelijkheid om de wedstrijd te bekijken tijdens het wedden",
                        name: "liveStreams", type: 'weight'
                    },
                    {
                        label: "Gebruiksvriendelijke website",
                        info: "Weddenschappen en wedstrijden makkelijk terugvinden, snelheid van de website",
                        name: "gebruiksVriendelijkeWebsite",
                        type: 'weight'
                    },
                    {
                        label: "Gebruiksvriendelijk spelen op mobiel", info: "Kwaliteit van de website op gsm en ipad",
                        name: "gebruiksVriendelijkeApp", type: 'weight'
                    },
                    {
                        label: "Klantendienst",
                        info: "Beschikbaarheid live chat, duur om antwoord te krijgen, kwaliteit van antwoord",
                        name: "klantenDienst",
                        type: 'weight'
                    },
                    {
                        label: "Promoties", info: "Hoe vaak en in welke mate loyale klanten worden beloond",
                        name: "bonussen", type: 'weight'
                    }
                ]
            },
            {
                header: 'Op welke website ben je reeds geregistreerd?',
                info: 'Op welke sites je ooit een account hebt aangemaakt',
                type: 'checkbox',
                key: 'alreadyRegistered',
                options: [
                    {
                        key: 'bet777',
                        name: 'Bet 777'
                    },
                    {
                        key: 'betfirst',
                        name: 'Betfirst'
                    },
                    {
                        key: 'napoleonGames',
                        name: 'Napoleon Games'
                    },
                    {
                        key: 'unibet',
                        name: 'Unibet'
                    },
                    {
                        key: 'circus',
                        name: 'Circus'
                    },
                    {
                        key: 'bingoal',
                        name: 'Bingoal'
                    },
                    {
                        key: 'goldenVegas',
                        name: 'Goldenvegas'
                    },
                    {
                        key: 'goldenPalace',
                        name: 'Goldenpalace'
                    },
                    {
                        key: 'bwin',
                        name: 'Bwin'
                    },
                    {
                        key: 'ladBrokes',
                        name: 'Ladbrokes'
                    }
                ]
            }
        ]
    }
};

function RadioQuestion({section, control, cardRef}) {
    const {
        field: {value, onChange},
        fieldState: {error}
    } = useController({
        name: section.name,
        control,
        rules: {
            required: {
                value: true,
                message: 'Dit is een verplicht veld'
            }
        },
        defaultValue: "",
    });

    return (
        <FormControl component="fieldset" error={!!error}>
            <FormHelperText>{error ? error.message : null}</FormHelperText>
            <RadioGroup value={value} onChange={(e) => {
                onChange(e);
                cardRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
            }}>
                {section.options.map(option => (
                    <Grid container justifyContent="flex-start" alignItems="center">
                        <Grid item xs={11}>
                            <FormControlLabel key={option.value} value={option.value} control={<Radio/>}
                                              label={(<span>{option.label}</span>)} />
                        </Grid>
                        <Grid item xs={1}>
                            <Info info={option.info}/>
                        </Grid>
                    </Grid>
                    ))}
            </RadioGroup>
        </FormControl>

    )
}

function CheckBoxQuestion({option, control}) {

    const {
        field: {value, onChange}
    } = useController({
        name: option.key,
        control,
        defaultValue: false,
    });

    const ref = useRef();

    return (
        <FormControlLabel
            ref={ref}
            control={<Checkbox checked={value} onChange={(e) => {
                onChange(e);
                ref.current.scrollIntoView({behavior: 'smooth', block: 'start'})
            }}/>}
            label={option.name}
        />
    );
}

function CheckBoxQuestions({section, control, cardRef}) {
    return (
        <FormControl component="fieldset">
            <FormGroup>
                {section.options.map((option) => (
                    <CheckBoxQuestion key={option.key} option={option} control={control}/>
                ))}
            </FormGroup>
        </FormControl>
    )
}

function Section({section, control, cardRef}) {

    switch (section.type) {
        case 'radio':
            return (<RadioQuestion section={section} control={control} cardRef={cardRef}/>)

        case 'multi':
            return (
                <Fragment>
                    {section.questions.map((question) => (
                        <div key={question.name}>
                            <Section section={question} control={control} cardRef={cardRef}/>
                        </div>
                    ))}
                </Fragment>
            )
        case 'weight':
            return (
                <Weight section={section} control={control}/>
            )
        case 'checkbox':
            return (
                <CheckBoxQuestions section={section} control={control} cardRef={cardRef}/>
            )
        default:
            return null;
    }
}

function isString(obj) {
  return typeof obj === 'string' || obj instanceof String;
}

function isBoolean(val) {
    return typeof val === 'boolean';
}

// function generateFields() {
//     const data = {
//       fields: []
//     };
//
//     for (const country in surveys) {
//         console.log(country);
//         for(const type in surveys[country]) {
//             console.log(type);
//             for (let i = 0; i < surveys[country][type].length; i++) {
//                 const section = surveys[country][type][i];
//                 console.log(section);
//                 switch(section.type) {
//                     case 'checkbox':
//                         for (const opt of section.options) {
//                             data.fields.push(opt.key);
//                         }
//                         break;
//                     case 'multi':
//                         for(const q of section.questions) {
//                             data.fields.push(q.name);
//                         }
//                         break;
//                     case 'radio':
//                         data.fields.push(section.key);
//                         break;
//                     default:
//                         throw new Error('Unknown type '+section.type);
//                 }
//             }
//         }
//     }
//
//     data.fields.push('casino');
//     data.fields.push('sport');
//
//     let remove = data.fields.indexOf('typeCasino');
//     if(remove > -1) {
//         data.fields = data.fields.filter(x => x !== 'typeCasino');
//         data.fields.push('slots');
//         data.fields.push('liveCasino');
//         data.fields.push('diceGames');
//     }
//
//     remove = data.fields.indexOf('typeBonus');
//     if(remove > -1) {
//         data.fields = data.fields.filter(x => x !== 'typeBonus');
//         //data.fields.splice(remove, 1);
//         data.fields.push('stortingsBonussen');
//         data.fields.push('freeSpins');
//         data.fields.push('toernooien');
//         data.fields.push('bonussenVoorVip');
//         data.fields.push('cashbacks');
//     }
//
//     data.fields = data.fields.filter((v, i, a) => a.indexOf(v) === i)
//
//     data.fields = data.fields.sort();
//
//     console.log(JSON.stringify(data));
//
//     let ormFields = '';
//     let resolver = '';
//     let config = '';
//
//     for (let i = 0; i < data.fields.length; i++) {
//         const f = data.fields[i];
//
//
//         ormFields += '    /**\n' +
//             '     * @var float\n' +
//             '     * @ORM\\Column(type="decimal", precision=7, scale=2)\n' +
//             '     */\n' +
//             '    private $'+f+';\n\n';
//
//         resolver += '$resolver->setDefault("'+f+'", 0);\n';
//         config += "'"+f+"' => (float)$this->"+f+",\n"
//
//     }
//
//     console.log(ormFields);
//     console.log(resolver);
//     console.log(config);
// }

function ConditionalSection({section, control}) {
    const val = useWatch({control: control, name: section.condition.key});

    if(!section.condition.cb(val)) {
        return null;
    }

    return <SectionWrapper section={section} control={control} />

}


function SectionWrapper({section, control}) {

    const cardRef = useRef();
    return <Box mt={2} mb={2}>
        <Card elevation={3} ref={cardRef}>
            <CardContent>
                <Typography color={"primary"} gutterBottom variant="h5"
                            component="h1">{section.header} {section.info &&
                <Info info={section.info}/>}</Typography>
                {section.subheader &&
                <Typography gutterBottom variant={"subtitle2"}>{section.subheader}</Typography>}

                <Section section={section} control={control} cardRef={cardRef}/>

            </CardContent>
        </Card>
    </Box>;
}

export function Survey({land, type}) {

    //generateFields();

    const [open, setOpen] = useState(false);
    const [surveyData, setSurveyData] = useState({});
    const [showError, setShowError] = useState(false);

    const onSubmit = useCallback(data => {

        //console.log(JSON.stringify(data, null, 2));

        if(type === 'casino') {
            data.casino = 1;
            data.sport = 0;
        } else {
            data.casino = 0;
            data.sport = 1;
        }
        delete data.type;

        for(const property in data) {

            if(isBoolean(data[property])) {
                data[property] = data[property] ? 1 : 0;
                continue;
            }

            if(isString(data[property])) {
                data[property] = parseInt(data[property]);
                continue;
            }
        }

        data.land = land;

        if(data.hasOwnProperty('typeCasino')) {
            data.slots = data.typeCasino === 0 ? 1 : 0;
            data.liveCasino = data.typeCasino === 1 ? 1 : 0;
            data.diceGames = data.typeCasino === 2 ? 1 : 0;

            delete data.typeCasino;
        }

        if(data.hasOwnProperty('typeBonus')) {
            data.stortingsBonussen = data.typeBonus === 0 ? 1 : 0;
            data.freeSpins = data.typeBonus === 1 ? 1 : 0;
            data.toernooien = data.typeBonus === 2 ? 1 : 0;
            data.cashbacks = data.typeBonus === 3 ? 1 : 0;
            data.bonussenVoorVip = data.typeBonus === 4 ? 1 : 0;

            delete data.typeBonus;
        }

        // console.log(JSON.stringify(data, null, 2));
        // return;

        //return axios.post('http://localhost:8000/api/surveys', data)
        return axios.post('https://api.betmanbegins.be/api/surveys', data)
            .then(res => {
                setSurveyData(res.data);
                setOpen(true);
            })
            .catch(e => {
                setShowError(true);
            });
    }, [setSurveyData, setOpen, setShowError, land, type]);

    const handleClose = () => setOpen(false);

    const {control, handleSubmit, formState: {isSubmitted, isValid, isSubmitting}} = useForm({
        shouldUnregister: true
    });

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>

                {surveys[land][type].map((section) => {

                        if(section.condition) {
                            return <ConditionalSection key={section.key} section={section} control={control} />
                        } else {
                            return (
                                <SectionWrapper key={section.key} section={section} control={control}/>
                            )
                        }
                    }
                )
                }

                {isSubmitted && !isValid && <Box mt={2} mb={2} textAlign={'center'}>
                            <Typography color={'primary'} variant={'button'}>Er zijn nog ongeldige velden!</Typography>
                </Box> }

                {type && <Box mt={2} mb={2} textAlign={'center'}>
                    <Button disabled={isSubmitting} style={{width: '100%', 'marginTop': '30px', 'marginBottom': '60px'}} variant={"contained"} type={"submit"} color={"secondary"} size={"large"}>
                        Match me!
                    </Button>
                </Box>}
            </form>

            <Dialog open={open} onClose={handleClose} maxWidth={'md'}>
                <DialogContent>
                    <Typography color={'textPrimary'}>
                        <ReactMarkdown>{surveyData.description}</ReactMarkdown>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={handleClose} color="primary">*/}
                    {/*    Sluit*/}
                    {/*</Button>*/}
                    <Button fullWidth href={surveyData.ref} style={{margin: '30px'}} color="secondary" variant={'contained'}>
                        &gt;&gt; Ontdek bookmaker &lt;&lt;
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={showError}
                autoHideDuration={6000}
                onClose={() => setShowError(false)}
                message="Oops, er is een fout opgetreden bij het verwerken van de survey!"
            />
        </Fragment>
    );
}